import { useLazyQuery, ApolloError } from '@apollo/client';

import { wickedAppVar, wickedApiVar } from '@global/apollo/reactiveVars';

import { GET_JOBS_BY_IDS } from '../apollo/queries';

import type { Job } from '../types';

const useGetJobsByIDs = (): {
  jobsData: Job[];
  loading: boolean;
  fetchJobs: (jobIDs: string[]) => void;
} => {
  // Get Wicked App and Api
  const wickedApp = wickedAppVar();
  const wickedApi = wickedApiVar();
  // Get Jobs By IDs Query
  const [getJobs, { data: jobsData, loading }] = useLazyQuery<{
    getJobsByID: Job[];
  }>(GET_JOBS_BY_IDS, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    onError: (error: ApolloError) => {
      console.log(error.message);
    },
  });
  const fetchJobs = (jobIDs: string[]) => {
    getJobs({
      variables: {
        jobIDs,
        wickedApp,
        wickedApi,
      },
    });
  };
  return {
    jobsData: jobsData === undefined ? [] : jobsData.getJobsByID,
    loading,
    fetchJobs,
  };
};

export default useGetJobsByIDs;
