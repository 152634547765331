import { useMutation } from '@apollo/client';

import { wickedAppVar, wickedApiVar } from '@global/apollo/reactiveVars';

import { DELETE_JOB } from '../apollo/queries';

const useDeleteJob = ({
  onCompleted,
  jobID,
  currentRev,
}: {
  onCompleted: (data: any) => void;
  jobID: string | null;
  currentRev: number | null;
}): any => {
  // Get Wicked App and Api
  const wickedApp = wickedAppVar();
  const wickedApi = wickedApiVar();
  // Query
  const [deleteJob, { loading, data: job, error }] = useMutation(DELETE_JOB, {
    variables: {
      jobID,
      currentRev,
      wickedApp,
      wickedApi,
    },
    onCompleted,
  });
  return {
    deleteJob,
    loading,
    error,
    job,
  };
};

export default useDeleteJob;
