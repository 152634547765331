import { useMutation } from '@apollo/client';

import { wickedAppVar, wickedApiVar } from '@global/apollo/reactiveVars';

import { CREATE_JOB } from '../apollo/queries';

import type { Job, JobType } from '../types';

interface useCreateJobOptions {
  onCompleted: (data: { createNewJob: Job }) => void;
}

const useCreateJob = ({ onCompleted }: useCreateJobOptions) => {
  // Query
  const [createJobMutation, { loading, data: job, error }] = useMutation(
    CREATE_JOB,
    {
      onCompleted,
    }
  );

  const createJob = (jobType: JobType) => {
    // Get Wicked App and Api
    const wickedApp = wickedAppVar();
    const wickedApi = wickedApiVar();
    createJobMutation({
      variables: {
        jobType: jobType.jobTypeID,
        wickedApp,
        wickedApi,
      },
    });
  };

  return {
    createJob,
    loading,
    error,
    job,
  };
};

export default useCreateJob;
