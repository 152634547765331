/* eslint-disable sonarjs/no-small-switch */

import {
  validateMandatory,
  validateTypes,
  appLevelValidations,
} from '@koireader/javascript-utilities-package';

import { multipleDocumentsVar } from '@global/apollo/reactiveVars';

import type { FormData, JobConfig } from '../../types';

const mandatoryCheck = (
  jobConfig: JobConfig,
  formData: FormData
): Record<string, any> => {
  try {
    const UILayout = jobConfig.UILayout;
    const fieldDetails = jobConfig.UILayout.fieldDetails;
    const fieldLayoutSequence = jobConfig.fieldLayoutSequence;
    // Prepare Complex Data
    const complexFormData = {
      data: [formData],
    };

    // Mandatory Validations
    const errors: any = {};
    const validateMandatoryResults = validateMandatory(
      complexFormData,
      UILayout,
      fieldLayoutSequence,
      true
    );
    if (validateMandatoryResults.success === false) {
      const results = validateMandatoryResults.requiredFields.complex;
      populateErrors(errors, results, fieldDetails, 'Field is mandatory');
    }

    // App level validations
    const appLevelErrors = appLevelValidations(
      multipleDocumentsVar() === true ? 'freightFFJob' : 'delivery-order',
      complexFormData,
      UILayout,
      fieldLayoutSequence,
      true
    );
    if (appLevelErrors.success === false) {
      const results = appLevelErrors.requiredFields.complex;
      populateErrors(errors, results, fieldDetails, 'Value is incorrect');
    }

    return errors;
  } catch (error) {
    console.error(error);
    return {};
  }
};

const typeCheck = (
  jobConfig: JobConfig,
  formData: FormData
): Record<string, any> => {
  try {
    const UILayout = jobConfig.UILayout;
    const fieldDetails = jobConfig.UILayout.fieldDetails;
    const fieldLayoutSequence = jobConfig.fieldLayoutSequence;

    // Prepare Complex Data
    const complexFormData = {
      data: [formData],
    };
    // Type Validations
    const errors: any = {};
    // Types Validations
    const validateTypeResults = validateTypes(
      complexFormData,
      UILayout,
      fieldLayoutSequence,
      true
    );
    if (validateTypeResults.success === false) {
      const results = validateTypeResults.mismatchedFields.complex;
      populateErrors(errors, results, fieldDetails, 'Value is invalid');
    }

    return errors;
  } catch (error) {
    console.error(error);
    return {};
  }
};

const populateErrors = (
  errors: any,
  results: any,
  fieldDetails: any,
  message: string
) => {
  const fieldNames = Object.keys(results);
  for (const fieldName of fieldNames) {
    if (fieldName === 'tables') {
      errors[fieldName] = results[fieldName];
    } else {
      switch (fieldDetails[fieldName].type) {
        case 'address': {
          // for (const name of Object.keys(
          //   results[fieldName]['addressDetails']
          // )) {
          //   errors[`${fieldName}`] = message;
          // }
          errors[`${fieldName}`] = message;
          break;
        }
        default: {
          errors[fieldName] = message;
        }
      }
    }
  }
};

const checkFormForErrors = {
  mandatoryCheck,
  typeCheck,
};

export default checkFormForErrors;
