import { ApolloError, useQuery } from '@apollo/client';

import { wickedAppVar, wickedApiVar } from '@global/apollo/reactiveVars';

import { GET_APP_API_CONFIG } from '../apollo/queries';

const useAppApiConfig = (): {
  configData: string | null;
  loading: boolean;
  refetch: () => void;
} => {
  // Get Wicked App and Api
  const wickedApp = wickedAppVar();
  const wickedApi = wickedApiVar();
  // Get Config By API Query
  const {
    data: configData,
    loading,
    refetch,
  } = useQuery<{
    getAppApiConfig: any;
  }>(GET_APP_API_CONFIG, {
    variables: {
      wickedApp,
      wickedApi,
    },
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    onError: (error: ApolloError) => {
      console.log(error.message);
    },
  });

  return {
    configData: configData?.getAppApiConfig || null,
    loading,
    refetch,
  };
};

export default useAppApiConfig;
