/* eslint-disable prefer-const */
import React from 'react';

import { useQuery, useLazyQuery, ApolloError } from '@apollo/client';

import { wickedAppVar, wickedApiVar } from '@global/apollo/reactiveVars';
import eventBus from '@utils/eventBus';
import { Events } from 'src/global/constants';

import useGetJobsByIDs from './useGetJobsByIDs';
import { GET_COUNT_BY_STATUS, GET_JOBS_BY_CURSOR } from '../apollo/queries';
import { currentJobVar } from '../apollo/reactiveVars';

import type { DocumentUploadResponse, Job } from '../types';

const NEXT = 'next';
const PREV = 'prev';
const CARD_WIDTH = 55;
const FETCH_POLICY = 'network-only';
const ERROR_POLICY = 'all';

interface getJobsByCursorResponse {
  getJobsByCursor: {
    direction: 'next' | 'prev';
    jobID: string;
    jobs: Job[];
  };
}

const useJobsInfinteList = (containerWidth?: number): any => {
  // Get Wicked App and Api
  const wickedApp = wickedAppVar();
  const wickedApi = wickedApiVar();
  // Jobs Array
  const [jobs, setJobs] = React.useState<Job[]>([]);
  const [isNextAvailable, setIsNextAvailable] = React.useState(true);
  const [isPreviousAvailable, setIsPreviousAvailable] = React.useState(true);
  const [total, setTotal] = React.useState<number>(0);

  // Get Job By Id
  const { fetchJobs, jobsData } = useGetJobsByIDs();

  React.useEffect(() => {
    if (jobsData.length === 0) return;
    const index = jobs.findIndex((job) => job.jobID === jobsData[0].jobID);
    if (index) {
      jobs[index] = jobsData[0];
      setJobs([...jobs]);
    }
  }, [jobsData]);

  React.useEffect(() => {
    eventBus.on(Events.subscriptionResponse, refetchJobs);
    return () => {
      eventBus.off(Events.subscriptionResponse, refetchJobs);
    };
  }, []);

  const refetchJobs = (eventData: CustomEventInit) => {
    const data: DocumentUploadResponse = eventData.detail;
    fetchJobs([data.jobID]);
  };

  // Get Count Query
  useQuery(GET_COUNT_BY_STATUS, {
    variables: {
      wickedApp,
      wickedApi,
    },
    fetchPolicy: FETCH_POLICY,
    errorPolicy: ERROR_POLICY,
    onError: (error: ApolloError) => {
      console.log(error.message);
    },
    onCompleted: (data: any) => {
      let total = 0;
      const counts = JSON.parse(data.getCountByStatusOfJobs);
      for (const i in counts) {
        total += counts[i];
      }
      setTotal(total);
    },
  });

  // Get Jobs Query
  const [getNextJobs, { data: nextJobsData, loading: nextJobsLoading }] =
    useLazyQuery<getJobsByCursorResponse>(GET_JOBS_BY_CURSOR, {
      fetchPolicy: FETCH_POLICY,
      errorPolicy: ERROR_POLICY,
      onError: (error: ApolloError) => {
        console.log(error.message);
      },
    });

  const [getPrevJobs, { data: prevJobsData, loading: prevJobsLoading }] =
    useLazyQuery<getJobsByCursorResponse>(GET_JOBS_BY_CURSOR, {
      fetchPolicy: FETCH_POLICY,
      errorPolicy: ERROR_POLICY,
      onError: (error: ApolloError) => {
        console.log(error.message);
      },
    });

  // Fetch Jobs Initially
  React.useEffect(() => {
    if (total > 0 && jobs.length === 0) {
      const currentJob = currentJobVar();
      if (currentJob) {
        const initialLimitCount = containerWidth
          ? Math.floor(containerWidth / CARD_WIDTH / 2)
          : 5;
        next(initialLimitCount);
        previous(initialLimitCount);
        setJobs([currentJob]);
      }
    }
  }, [total]);

  // Add Next Jobs to Jobs Array
  React.useEffect(() => {
    if (nextJobsData !== undefined) {
      if (nextJobsData.getJobsByCursor?.jobs?.length === 0) {
        setIsNextAvailable(false);
        return;
      }
      const newJobs = nextJobsData.getJobsByCursor.jobs.filter(
        (job) => jobs.some((oldJob) => oldJob.jobID === job.jobID) === false
      );
      setJobs((jobs) => [...jobs, ...newJobs]);
    }
  }, [nextJobsData]);

  // Add Prev Jobs to Jobs Array
  React.useEffect(() => {
    if (prevJobsData !== undefined) {
      if (prevJobsData.getJobsByCursor?.jobs?.length === 0) {
        setIsPreviousAvailable(false);
        return;
      }
      const newJobs = prevJobsData.getJobsByCursor.jobs.filter(
        (job) => jobs.some((oldJob) => oldJob.jobID === job.jobID) === false
      );
      setJobs((jobs) => [...newJobs, ...jobs]);
    }
  }, [prevJobsData]);

  // Next
  const next = (limit = 3) => {
    let jobID;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    jobID = jobs.length === 0 ? currentJobVar()?.jobID : jobs.at(-1).jobID;
    getNextJobs({
      variables: {
        jobID,
        direction: NEXT,
        limit,
        wickedApp,
        wickedApi,
      },
    });
  };

  // Previous
  const previous = (limit = 3) => {
    let jobID;
    jobID = jobs.length === 0 ? currentJobVar()?.jobID : jobs[0].jobID;
    getPrevJobs({
      variables: {
        jobID,
        direction: PREV,
        limit,
        wickedApp,
        wickedApi,
      },
    });
  };

  return {
    jobs,
    next,
    previous,
    prevJobsLoading,
    nextJobsLoading,
    isNextAvailable,
    isPreviousAvailable,
  };
};

export default useJobsInfinteList;
