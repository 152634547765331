import { ApolloError, useLazyQuery } from '@apollo/client';

import { wickedAppVar, wickedApiVar } from '@global/apollo/reactiveVars';

import { GET_JOBS_BY_API, GET_JOB_COUNT_BY_API } from '../apollo/queries';

import type { Job } from '../types';

interface fetchJobsType {
  jobIDString?: string | null;
  status?: string[] | null;
  type?: string[] | null;
  limit?: number;
  startAfter?: number;
  dateTypeForFilter?: 'createdAt' | 'updatedAt' | null;
  dateRangeForFilter?: string[] | null;
  updatedAtDateRange?: [string, string] | null;
}

const useApiJobs = (): {
  jobs: Job[];
  total: number;
  loading: boolean;
  fetchJobs: (variables: fetchJobsType) => void;
} => {
  // Get Wicked App and Api
  const wickedApp = wickedAppVar();
  const wickedApi = wickedApiVar();
  // Get Jobs By API Query
  const [getJobs, { data: jobsData, loading }] = useLazyQuery<{
    getJobsByAPI: Job[];
  }>(GET_JOBS_BY_API, {
    fetchPolicy: 'network-only',
    // Maybe change errorPolicy to 'ignore' as queued jobs get 'Unable to fetch number of pages error' ?
    errorPolicy: 'all',
    onError: (error: ApolloError) => {
      console.log(error.message);
    },
  });

  // Get Job Count By API Query
  const [getJobCount, { data: jobCountData }] = useLazyQuery<{
    getJobCountByAPI: number;
  }>(GET_JOB_COUNT_BY_API, {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    onError: (error: ApolloError) => {
      console.log(error.message);
    },
  });

  // Fetch Jobs Func
  const fetchJobs = ({
    jobIDString,
    status,
    type,
    limit,
    startAfter,
    dateTypeForFilter,
    dateRangeForFilter,
  }: fetchJobsType) => {
    getJobCount({
      variables: {
        jobIDString,
        status,
        type,
        dateTypeForFilter,
        dateRangeForFilter,
        wickedApp,
        wickedApi,
      },
    });

    getJobs({
      variables: {
        jobIDString,
        status,
        type,
        limit,
        startAfter,
        dateTypeForFilter,
        dateRangeForFilter,
        wickedApp,
        wickedApi,
      },
    });
  };

  return {
    jobs: jobsData === undefined ? [] : jobsData?.getJobsByAPI,
    total: jobCountData?.getJobCountByAPI || 0,
    loading,
    fetchJobs,
  };
};

export default useApiJobs;
