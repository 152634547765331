import { useReactiveVar } from '@apollo/client';

import { imageCredentialsVar } from '@global/apollo/reactiveVars';
import getDocumentImage from '@utils/getDocumentImage';

import {
  currentJobVar,
  currentDocumentVar,
  imageGalleryVar,
} from '../apollo/reactiveVars';

import type { Job } from '../types';

interface ChangeJobProps {
  job: Job | null;
  key: number;
  currentJobIndex: number;
}

const useChangeJob = () => {
  const imageCredentials = useReactiveVar(imageCredentialsVar);

  const changeJob = ({ job, key, currentJobIndex }: ChangeJobProps) => {
    currentJobVar(job);
    if (
      job?.documents &&
      Array.isArray(job?.documents) &&
      job?.documents.length > 0
    ) {
      const currentDocument = job?.documents[0];
      currentDocumentVar(currentDocument);
      if (currentDocument?.totalPages > 0 && imageCredentials) {
        getDocumentImage(
          imageCredentials,
          currentDocument.docID,
          currentDocument.docName,
          0,
          'display'
        ).then((imageUrl) => {
          imageGalleryVar({
            image: imageUrl,
            direction: key > currentJobIndex ? 'right' : 'left',
          });
        });
      } else {
        imageGalleryVar(null);
      }
    } else {
      imageGalleryVar(null);
      currentDocumentVar(null);
    }
  };
  return {
    changeJob,
  };
};

export default useChangeJob;
