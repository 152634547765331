import * as Sentry from '@sentry/nextjs';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

import getAccessTokenWeb from './getAccessTokenWeb';
import {
  accessTokenVar,
  subscribedDashboardAppsVar,
  userVar,
  // wickedApiVar,
  wickedAppVar,
} from '../apollo/reactiveVars';
import { GUEST_ROUTES } from '../constants';

import type { User, WickedApi } from '../types';

const wickedInit = async () => {
  // Skip wicked initialization for guest routes
  if (GUEST_ROUTES.has(window.location.pathname)) return;

  const NEXT_PUBLIC_BACKEND_HTTP_URL = process.env
    .NEXT_PUBLIC_BACKEND_HTTP_URL as string;
  const accessToken = await getAccessTokenWeb();
  accessTokenVar(accessToken);
  // Decode User from Access Token
  const decoded: any = jwtDecode(accessToken);
  const user: User = decoded.payload;
  userVar(user);
  Sentry.setUser({ email: user.email, username: user.name, id: user.sub });

  axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  let response = await axios.get(
    NEXT_PUBLIC_BACKEND_HTTP_URL + '/dashboard/users/v1/applications'
  );
  // Get Wicked App
  let wickedApp: string;
  if (Array.isArray(response.data) && response.data.length > 0) {
    wickedApp = response.data[0].id;
  } else {
    throw new Error('No Wicked Applications found');
  }
  Sentry.setTag('wickedApp', wickedApp);

  // Get Wicked APIs
  // let wickedApi: WickedApi;
  const subscribedDashboardApps: WickedApi[] = [];
  response = await axios.get(
    NEXT_PUBLIC_BACKEND_HTTP_URL +
      `/dashboard/users/v1/subscriptions/${wickedApp}`
  );

  if (Array.isArray(response.data) && response.data.length > 0) {
    // wickedApi = response.data[0].api;

    // Save list of all APIs subscribed by the user
    response.data.map((apiListItem) =>
      subscribedDashboardApps.push(apiListItem.api)
    );
  } else {
    throw new Error('No Wicked API found');
  }

  // Sentry.setTag('wickedApi', wickedApi);
  console.log('Subscribed Dashboard Apps', subscribedDashboardApps);
  console.log({ wickedApp });

  subscribedDashboardAppsVar(subscribedDashboardApps);
  wickedAppVar(wickedApp);
  // wickedApiVar(wickedApi);
};

export default wickedInit;
