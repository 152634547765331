/* eslint-disable sonarjs/no-duplicate-string */
// List of valid file types
export const VALID_FILE_TYPES = [
  'application/pdf',
  'image/png',
  'image/jpeg',
  'image/tiff',
];

// Support email
export const KOIREADER_SUPPORT_EMAIL = 'support@koireader.com';

// Max file upload size limit in MB
export const MAX_FILE_SIZE_UPLOAD_LIMIT = 8_000_000;

// Document colors - used for assigning unique colors to documents in a job
export const DOCUMENT_COLORS = [
  '#8d7fff',
  '#ff4eb8',
  '#76ffc4',
  '#ff3d6b',
  '#0075fa',
  '#ffe4b4',
  '#faa72b',
  '#00a0d0',
  '#f5aed6',
  '#ff5500',
  '#aaff68',
  '#a5edff',
  '#e6ffab',
  '#ffa8a8',
  '#eb6dff',
  '#cdcdcd',
  '#ae43ff',
  '#ff6972',
  '#3bdaf3',
  '#ffe24e',
];

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DISPLAY_DATE_FORMAT = 'MMM D, YYYY';
export const TIME_FORMAT = 'HH:mm';

// Minimum date timestamp - the date before which users are not allowed to select a date in the date-picker
export const MINIMUM_DATE_TIMESTAMP = 946_688_400_000;

// Guest routes - the routes which can be accessed by any unauthenticated user
// Append to the array to allow more routes
export const GUEST_ROUTES = new Set(['/login']);

export const SPLIT_DOCS_INITIAL_PATH =
  '/SplitScreen-sample-images/split-screen-doc';

// Job statuses enum
export enum JobStatus {
  all = 'all',
  queued = 'queued',
  digitized = 'digitized',
  approved = 'approved',
  tms = 'in_third_party_process',
  processed = 'processed',
  notProcessed = 'not_processed',
  // pendingApproval = 'pending_approval',
  // partiallyProcessed = 'partially_processed',
}

// Event enum for event bus
export enum Events {
  jobCreated = 'jobCreated',
  jobDeleted = 'jobDeleted',
  formRefresh = 'formRefresh',
  tableOpen = 'tableOpen',
  tableClosed = 'tableClosed',
  documentImageRefresh = 'documentImageRefresh',
  documentAssigned = 'documentAssigned',
  updateJobCount = 'updateJobCount',
  imageCredentialsExpired = 'imageCredentialsExpired',
  subscriptionResponse = 'subscriptionResponse',
}

// Form group labels enum
export enum FormGroupLabels {
  basicInformation = 'Basic Information',
  containerDetails = 'Container Details',
  tables = 'Tables',
  containerTable = 'Container Table',
  addresses = 'Addresses',
  events = 'Events',
  chassisDetails = 'Chassis Details',
  location = 'Location',
  routing = 'Routing',
  chargeables = 'Chargeables',
  shippingInformation = 'Shipping Information',
  appointmentDetails = 'Appointment Details',
}

export enum SettingsTabs {
  businessRules = 'businessRules',
}

export enum AppGroupNames {
  smartWarehousing = 'Warehouse Intelligence',
  smartYards = 'Smart Yards',
  intelligentFactories = 'Intelligent Factories',
  smartPorts = 'Smart Ports',
  thirdPartyIntegrations = 'Third-Party Integrations',
  documentDigitalization = 'Document Digitalization',
  industrialOmniverse = 'Industrial Omniverse',
  insights = 'Insights',
}

export enum AppNames {
  // Smart Warehousing
  orderCartAudit = 'Order Cart Audit',
  smartLabelScan = 'Smart Label Scan',
  conveyorLabelScan = 'Conveyor Label Scan',
  visionPicking = 'Vision Picking',
  inventoryVisibility = 'Inventory Visibility',
  dockDoorVisibility = 'Dock Door Visibility',
  asnAuditTrail = 'ASN Audit Trail',
  activityTracking = 'Activity Tracking',
  damageDetection = 'Damage Detection',
  warehouseProductSortation = 'Product Sortation',
  reverseLogistics = 'Reverse Logistics',
  // eslint-disable-next-line sonarjs/no-duplicate-string
  warehouseAssetTracking = 'Asset Tracking',
  itemDimensioning = 'Item Dimensioning',
  workerSafety = 'Worker Safety',
  productScan = 'Product Scan',

  // Smart Yards
  lastMileDelivery = 'Last Mile Delivery',
  yardAutonomousGates = 'Autonomous Gates',
  assetVisibility = 'Asset Visibility',
  ePOD = 'ePOD',
  predictiveDockDoor = 'Predictive Dock Door',
  trafficManagement = 'Traffic Management',
  fmcsaCompliance = 'FMCSA Compliance',
  perimeterSurveillance = 'Perimeter Surveillance',
  yardCheck = 'Yard Check',

  // Intelligent Factories
  skuRecognition = 'SKU Recognition',
  barcodeScanning = 'Barcode Scanning',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  factoryProductSortation = 'Product Sortation',
  arVision = 'AR Vision',
  defectDetection = 'Defect Detection',
  qualityControl = 'Quality Control',

  // Smart Ports
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  portAutonomousGates = 'Autonomous Gates',
  preventiveMaintenance = 'Preventive Maintenance',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  portAssetTracking = 'Asset Tracking',
  lashingUnlashing = 'Lashing / Unlashing',

  // Third-Party Integrations
  realWear = 'RealWear',
  teamViewer = 'TeamViewer',
  dematic = 'Dematic',
  zebra = 'Zebra',
  autoStore = 'AutoStore',

  // Document Digitalization
  drayage = 'Drayage',
  deliveryOrder = 'Delivery Order',
  freightForwarding = 'Freight Forwarding',
  otr = 'OTR',
  customs = 'Customs',
  maritime = 'Maritime',
  procurement = 'Procurement',
  accountsPayable = 'Accounts Payable',
  claims = 'Claims',

  // Industrial Omniverse
  warehouse = 'Warehouse',
  yard = 'Yard',
  port = 'Port',
  factory = 'Factory',
  syntheticAssets = 'Synthetic Assets',

  // Insights
  reports = 'Reports',
  analytics = 'Analytics',
}
