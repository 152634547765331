import React from 'react';

import { useLazyQuery } from '@apollo/client';
import { useTranslation } from 'next-i18next';

import StickyNotification from '@components/elements/StickyNotification';
import { wickedAppVar, wickedApiVar } from '@global/apollo/reactiveVars';
import {
  MAX_FILE_SIZE_UPLOAD_LIMIT,
  VALID_FILE_TYPES,
} from 'src/global/constants';

import { GET_PRESIGNED_POST_SDK } from '../apollo/queries';
import { updatingJobsVar } from '../apollo/reactiveVars';

const VALID_FILE_TYPES_SET = new Set(VALID_FILE_TYPES);

const validateFileSize = (fileSize: number, maxFileSizeWarning: string) => {
  if (fileSize < MAX_FILE_SIZE_UPLOAD_LIMIT) {
    return true;
  } else {
    StickyNotification({
      type: 'error',
      title: 'Failure',
      description: `${maxFileSizeWarning} ${
        MAX_FILE_SIZE_UPLOAD_LIMIT / 1_000_000
      }MB`,
      placement: 'bottomRight',
      key: String(fileSize),
    });
    return false;
  }
};

const validateFileType = (fileType: string, invalidFileWarning: string) => {
  if (VALID_FILE_TYPES_SET.has(fileType)) {
    return true;
  } else {
    StickyNotification({
      type: 'error',
      title: 'Failure',
      description: invalidFileWarning,
      placement: 'bottomRight',
      key: fileType,
    });
    return false;
  }
};

const useDocumentUpload = (): {
  uploadDocument: (jobID?: string | undefined) => void;
  loading: boolean;
} => {
  const { t: common } = useTranslation();
  // Get Wicked App and Api
  const wickedApp = wickedAppVar();
  const wickedApi = wickedApiVar();
  // Job ID
  const [file, setFile] = React.useState<any | null>(null);

  const handlDocUploadFailure = (docID: string, jobID: string) => {
    // Remove job from the updating jobs' list, get jobID from the fields data
    updatingJobsVar(updatingJobsVar().filter((id) => id !== jobID));
    StickyNotification({
      type: 'error',
      title: 'Failure',
      description: common('documentUpload.uploadFailure'),
      placement: 'bottomRight',
      key: docID,
    });
  };

  // On Mutation Complete
  const onCompleted = (data: any) => {
    const { id, url, fields } = data.getPresignedPostSDK;
    const fieldsData = JSON.parse(fields);
    console.log(id, url, fieldsData);
    const formData = new FormData();
    // eslint-disable-next-line unicorn/no-array-for-each
    Object.entries(fieldsData).forEach(([k, v]: [k: any, v: any]) => {
      formData.append(k, v);
    });
    formData.append('file', file);

    fetch(url, {
      method: 'POST',
      body: formData,
    })
      .then((res: any) => {
        console.log(res);
        if (!res.ok) {
          handlDocUploadFailure(id, fieldsData['x-amz-meta-job_id']);
          return;
        }
        StickyNotification({
          type: 'success',
          title: 'Success',
          description: common('documentUpload.uploadSuccess'),
          placement: 'bottomRight',
          key: id,
        });
      })
      .catch((error: any) => {
        console.error('File upload error', error);
        handlDocUploadFailure(id, fieldsData['x-amz-meta-job_id']);
      });
  };

  // Mutation
  const [getPresignedPostData, { loading }] = useLazyQuery(
    GET_PRESIGNED_POST_SDK,
    {
      fetchPolicy: 'no-cache',
      onCompleted,
    }
  );

  // Add Document Func
  const uploadDocument = (jobID: string | undefined) => {
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.accept = VALID_FILE_TYPES.toString();
    inputElement.style.display = 'none';
    inputElement.addEventListener('change', (e: Event) => {
      const input = e.target as HTMLInputElement;
      if (input.files && input.files.length > 0) {
        // Get File
        const file = input.files[0];
        if (
          validateFileSize(
            file.size,
            common('documentUpload.maxFileSizeWarning')
          ) &&
          validateFileType(file.type, common('documentUpload.invalidFileType'))
        ) {
          // Set Job Updating
          if (jobID !== undefined) {
            const jobIDs = updatingJobsVar();
            updatingJobsVar([...jobIDs, jobID as string]);
          }

          const fileNameWithExtn = file.name;
          const fileType = file.type.split('/').pop();
          setFile(file);
          getPresignedPostData({
            variables: {
              fileNameWithExtn,
              fileType,
              jobID,
              wickedApp,
              wickedApi,
            },
          });
        }
      }
      inputElement.remove();
    });
    document.body.append(inputElement);
    inputElement.click();
  };

  return {
    uploadDocument,
    loading,
  };
};

export default useDocumentUpload;
