import React from 'react';

import { useReactiveVar } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';

import { useEnumValues } from '@apps/common/hooks';
import { enumValuesVar } from '@common/apollo/reactiveVars';
import {
  subscribedDashboardAppsVar,
  wickedApiVar,
  wickedAppVar,
} from '@global/apollo/reactiveVars';

import type { WickedApi } from '../types';

// Determine if the user has access to the provided wickedApi
const useCheckWickedApi = (wickedApi: WickedApi) => {
  // Get list of apps the user is subscribed to
  const subscribedDashboardApps = useReactiveVar(subscribedDashboardAppsVar);

  // Fetch enum values
  const { fetchEnumValues, enumValues } = useEnumValues();

  // Update enum values
  React.useEffect(() => {
    if (!enumValues) return;
    enumValuesVar(enumValues);
  }, [enumValues]);

  React.useEffect(() => {
    // Redirect to root URL if not subscribed to the dashboard app
    if (!subscribedDashboardApps.includes(wickedApi)) {
      window.location.href = '/';
      return;
    }

    // fetch enum values for the given wickedApi (dashboard app)
    fetchEnumValues({
      wickedApp: wickedAppVar(),
      wickedApi: wickedApi,
    });

    // Set wickedApi
    wickedApiVar(wickedApi);
    // Set Sentry tags
    Sentry.setTag('wickedApi', wickedApi);

    console.log({ wickedApi: wickedApi });
  }, [subscribedDashboardApps]);
};

export default useCheckWickedApi;
