import React from 'react';

import {
  ApolloClient,
  NormalizedCacheObject,
  createHttpLink,
  InMemoryCache,
  split,
  useReactiveVar,
} from '@apollo/client';
import {
  ApolloLink,
  Operation,
  FetchResult,
  Observable,
  from,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { getMainDefinition } from '@apollo/client/utilities';
import { print } from 'graphql';
import { createClient, ClientOptions, Client } from 'graphql-ws';

import { accessTokenVar } from '@global/apollo/reactiveVars';
import typePolicies from '@global/apollo/typePolicys';
import getAccessTokenWeb from '@utils/getAccessTokenWeb';

const MAX_RETRY_DELAY = 10_000;

let client: ApolloClient<NormalizedCacheObject> | null = null;

const useApolloClient = () => {
  const accessToken = useReactiveVar(accessTokenVar);

  // Create Client
  React.useEffect(() => {
    if (accessToken === null) return;
    // Auth Link
    const authLink = setContext(async (_, { headers }) => {
      const accessToken = await getAccessTokenWeb();
      return {
        headers: {
          ...headers,
          authorization: 'Bearer ' + accessToken,
        },
      };
    });

    // HTTP Link
    //eslint-disable-next-line unicorn/prefer-spread
    const httpLink = authLink.concat(
      createHttpLink({
        uri: process.env.NEXT_PUBLIC_BACKEND_HTTP_URL + '/graphql',
      })
    );

    // Websocket Link
    const wsLink = new WebSocketLink({
      url: process.env.NEXT_PUBLIC_BACKEND_WS_ENDPOINT || '',
      // Delay between retries
      // Note: 'max' option of retryLink is somehow overridden in wsLink, hence added retryWait explicitly
      retryWait: async () => {
        await new Promise((resolve) => setTimeout(resolve, MAX_RETRY_DELAY));
      },
      connectionParams: () => {
        return {
          authorization: 'Bearer ' + accessToken,
        };
      },
    });

    // Retry link
    const retryLink = new RetryLink({
      attempts: {
        max: Number.POSITIVE_INFINITY,
        retryIf: (error) => !!error,
      },
      delay: {
        initial: 300,
        // NOTE: somehow 'max' option added here is not respected by the wsLink, hence 'retryWait' with same delay is added in wsLink
        max: MAX_RETRY_DELAY,
        jitter: true,
      },
    });

    // Split Link
    const splitLink = split(
      //only create the split in the browser
      // split based on operation type
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      httpLink
    );

    // Error Link
    const errorLink = onError(
      ({ graphQLErrors, networkError, operation, forward }) => {
        if (graphQLErrors) {
          for (const err of graphQLErrors) {
            if (err.extensions.code === 'UNAUTHENTICATED') {
              // Redirect user to login page
              window.location.href = '/login';
              // return forward(operation);
            }
          }
        }
        if (networkError && networkError.message.includes('4403')) {
          return forward(operation);
        }
      }
    );

    // Create new Client Instance
    const apolloClient = new ApolloClient({
      link: from([retryLink, errorLink, splitLink]),
      cache: new InMemoryCache({
        typePolicies,
      }),
    });

    client = apolloClient;
  }, [accessToken]);

  return {
    client,
  };
};

// Custom Websocket Link
class WebSocketLink extends ApolloLink {
  private client: Client;

  constructor(options: ClientOptions) {
    super();
    this.client = createClient(options);
  }

  public request(operation: Operation): Observable<FetchResult> {
    return new Observable((sink) => {
      return this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: (err) => {
            if (err instanceof Error) {
              return sink.error(err);
            }

            if (err instanceof CloseEvent) {
              return sink.error(
                // reason will be available on clean closes
                new Error(
                  `Socket closed with event ${err.code} ${err.reason || ''}`
                )
              );
            }

            return sink.error(
              new Error('Error Connecting to GraphQL WS Server')
            );

            // return sink.error(
            //   new Error(
            //     (err as GraphQLError[]).map(({ message }) => message).join(', ')
            //   )
            // );
          },
        }
      );
    });
  }
}

export default useApolloClient;
