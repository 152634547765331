import { useMutation } from '@apollo/client';

import { ADD_DOCUMENT_TO_JOB } from '@common/apollo/queries';
import { wickedAppVar, wickedApiVar } from '@global/apollo/reactiveVars';

const useAddDoumentToJob = ({
  onCompleted,
}: {
  onCompleted: (data: any) => void;
}): any => {
  // Get Wicked App and Api
  const wickedApp = wickedAppVar();
  const wickedApi = wickedApiVar();
  // Query
  const [mutation, { loading, data: document, error }] = useMutation(
    ADD_DOCUMENT_TO_JOB,
    {
      onCompleted,
    }
  );

  const addDocumentToJob = (
    jobID: string,
    docID: string,
    currentRev: number
  ) => {
    mutation({
      variables: {
        jobID,
        docID,
        currentRev,
        wickedApp,
        wickedApi,
      },
    });
  };

  return {
    addDocumentToJob,
    loading,
    error,
    document,
  };
};

export default useAddDoumentToJob;
